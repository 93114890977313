const newsBlock = document.querySelector('section.news-block');

if(newsBlock) {
    let filterItems = newsBlock.querySelectorAll('.news-filter li a');
    let activeFilter = newsBlock.querySelector('.news-filter .active-input a');
    let container = newsBlock.querySelector('.container');
    let moreBtn = newsBlock.querySelector('button.view-more');
    let currentYear = newsBlock.querySelector('.years li .selected').getAttribute('data-slug');
    let currentCount;
    let categories = false;
    let showAll = false;
    let hasTitle = false;
    if(container.classList.contains('show-all')) {
        showAll = true;
    }
    currentCount = showAll ? -1 : 6;
    if(container.classList.contains('financial')) {
        categories = true;
    }
    if(newsBlock.querySelector('.news-title')) {
        hasTitle = true;
    }
    if(activeFilter) {
        activeFilter.addEventListener('click', function(e) {
            e.preventDefault();
            if(newsBlock.querySelector('.select-input').classList.contains('active')) {
                newsBlock.querySelector('.select-input').classList.remove('active');
                newsBlock.querySelector('.active-input').classList.remove('options-open');
            }
            else {
                newsBlock.querySelector('.select-input').classList.add('active');
                newsBlock.querySelector('.active-input').classList.add('options-open');
            }

        });
    }
    filterItems.forEach(function(item, index) {
        item.addEventListener('click', function(e) {
            e.preventDefault();
            newsBlock.querySelectorAll('.years li a').forEach(function(item, index){
                item.classList.remove('selected');
            });
            this.classList.add('selected');
            currentYear = this.getAttribute('data-slug');
            currentCount = showAll ? -1 : 6;
            getPosts();
            newsBlock.querySelector('.active-input span').innerHTML = this.innerHTML;
            newsBlock.querySelector('.select-input').classList.remove('active');
            newsBlock.querySelector('.active-input').classList.remove('options-open');
        });
    });

    if(moreBtn) {
        moreBtn.addEventListener('click', function(e) {
            e.preventDefault();
            currentCount = showAll ? -1 : currentCount += 6;
            getPosts();
        });
    }

    function setHover() {
        let wrapper = newsBlock.querySelector('.news-wrapper');
        let articles = newsBlock.querySelectorAll('article.tease');
        articles.forEach(function(article, index) {
            article.addEventListener('mouseover', function() {
                wrapper.classList.add('hovered');
            });
            article.addEventListener('mouseleave', function() {
                wrapper.classList.remove('hovered');
            });
        });
    }
    setHover();

    function getPosts() {
        let data = new FormData();
        data.append('action', 'get_posts');
        data.append('year', currentYear);
        data.append('count', currentCount);
        data.append('categories', categories);
        data.append('title', hasTitle);
        $.ajax({
            type: 'POST',
            url: macro_scripts.ajax_url,
            cache: false,
            processData: false,
            contentType: false,
            data: data,
            success: function (res) {
                container.innerHTML = res;
                if(moreBtn && newsBlock.querySelectorAll('.posts article.tease').length < newsBlock.querySelector('.news-wrapper').getAttribute('data-count')) {
                    moreBtn.classList.remove('hide');
                }
                else if(moreBtn){
                    moreBtn.classList.add('hide');
                }
                setHover();
            }
        });
    }
}