/*
 * Calculate header height.

 * In CSS, use the following to get the height:
 *   height: var(--headerHeight);
 */
const setupShortcutsHeight = () => {
    let shortcuts = document.querySelector('.shortcuts');
    if(shortcuts) {
        let sh = $(shortcuts).outerHeight(true);
        document.documentElement.style.setProperty('--shortcutsHeight', `${sh}px`);
    }
}
setupShortcutsHeight();
window.addEventListener('resize', setupShortcutsHeight);
