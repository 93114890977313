const facilitiesBlock = document.querySelector('section.facilities');

if(facilitiesBlock) {
    let filterItems = facilitiesBlock.querySelectorAll('.filter li a');
    let activeFilter = facilitiesBlock.querySelector('.filter .active-input a');

    if(activeFilter) {
        activeFilter.addEventListener('click', function(e) {
            e.preventDefault();
            if(facilitiesBlock.querySelector('.select-input').classList.contains('active')) {
                closeFilter(facilitiesBlock);
            }
            else {
                facilitiesBlock.querySelector('.active-input').classList.add('options-open');
                facilitiesBlock.querySelector('.select-input').classList.add('active');
                facilitiesBlock.querySelector('ul').classList.add('open');
            }

        });
    }
    filterItems.forEach(function(item, index) {
        item.addEventListener('click', function(e) {
            e.preventDefault();
            let slug = this.getAttribute('data-slug');
            facilitiesBlock.querySelectorAll('ul li a').forEach(function(item, index){
                item.classList.remove('selected');
            });
            this.classList.add('selected');
            closeFilter(facilitiesBlock);
            facilitiesBlock.querySelectorAll('.facility').forEach(function(item, index) {
                item.classList.remove('selected');
            });
            facilitiesBlock.querySelector('.facility.' + slug).classList.add('selected');
        });
    });

    function closeFilter(element) {
        element.querySelector('.active-input').classList.remove('options-open');
        element.querySelector('ul').classList.remove('open');
        setTimeout(function() {
            element.querySelector('.select-input').classList.remove('active');
        }, 300);
    }
}