// Menu on scroll
const breakpoint = 1024;
const header = document.querySelector('header');

if(header) {
    let headerHeight = header.offsetHeight;
    let lastScrollTop = 0;

 $(window).scroll(function (e) {
    let scrollTop = $(this).scrollTop();
    if ($(window).width() < breakpoint) {
        if (scrollTop <= 2) {
            header.classList.remove('sticky', 'show', 'animate', 'up');
        } 
        else if (scrollTop === 0) {
            header.classList.remove('sticky', 'show', 'animate', 'up');
        }
        else if(scrollTop > headerHeight) {
            if (scrollTop < lastScrollTop) { // Scrolling up
                header.classList.add('sticky', 'animate', 'show')
                header.classList.remove('down');
            } else {
                header.classList.add('sticky');
                header.classList.remove('show');
            }
        }
   }
    else if ($(window).width() >= breakpoint && scrollTop > 0) {
        if (scrollTop < lastScrollTop) { // Scrolling up
            header.classList.add('sticky', 'animate', 'show', 'up')
            header.classList.remove('down');
        } else {
            header.classList.add('sticky', 'down');
            header.classList.remove('show', 'up');
        }
    }
    else {
        header.classList.remove('sticky', 'show', 'animate', 'up');
    }
    lastScrollTop = scrollTop;
 });

 if($(window).width() >= 1440) {
    $('header').find('.menu-content li.rigs-wrapper').wrapAll('<li class="menu-item-wrapper"><ul class="menu-list"></ul></li>');
    $('header').find('.menu-content li.contact-wrapper').wrapAll('<li class="menu-item-wrapper"><ul class="menu-list"></ul></li>');
 }
 $(window).resize(function() {
    if($(window).width() < 1440) {
        $('header').find('.menu-content li.rigs-wrapper').first().unwrap('.menu-list').unwrap('.menu-item-wrapper');
        $('header').find('.menu-content li.contact-wrapper').first().unwrap('.menu-list').unwrap('.menu-item-wrapper');
    }
    else {
        $('header').find('.menu-content li.rigs-wrapper').wrapAll('<li class="menu-item-wrapper"><ul class="menu-list"></ul></li>');
        $('header').find('.menu-content li.contact-wrapper').wrapAll('<li class="menu-item-wrapper"><ul class="menu-list"></ul></li>');
    }
});


// hamburger nav
    window.addEventListener('load', (event) => {

        const button = document.querySelector(".menu-btn-wrapper")
        const navBar = document.querySelector("header .menu-content")
        const header = document.querySelector("header")
        const body = document.querySelector("body");
        const parent = document.querySelectorAll(".menu-item-has-children >a")
        const arrow = document.querySelectorAll(".menu-item-has-children >svg")
        const backBtn = document.querySelector('header .menu-back-btn');

        button.onclick = function toggleMenu() {
            if (navBar.matches('.show')) {
                navBar.classList.remove("anim");
                setTimeout(function() {
                    navBar.classList.remove("show");
                }, 300);
                header.querySelector(".wrapper").classList.remove('anim');
                button.querySelector('button').classList.remove("menu-close");
                header.classList.remove('show-menu', 'menu-bg');
                body.classList.remove('no-scroll');
                header.querySelector('.menu-btn-wrapper p').innerHTML = 'Menu';
                if(header.classList.contains('submenu-active')){
                    setTimeout(function() {
                        header.querySelector('li.show-ul').classList.remove("show-ul");
                        header.classList.remove('submenu-active');
                    }, 300);
                }               
            }
            else {
                navBar.classList.add("show");
                navBar.classList.add("anim");
                button.querySelector('button').classList.add("menu-close");
                header.classList.add('menu-bg');
                setTimeout(function() {
                    header.classList.add('show-menu');
                }, 300);
                header.querySelector(".wrapper").classList.add('anim');
                body.classList.add('no-scroll');
                header.querySelector('.menu-btn-wrapper p').innerHTML = 'Close';

                // Hides dropdown when menu is hidden
                parent.forEach(function (ele) {
                    ele.classList.remove("show-ul");
                });
            }
        };

        if($(window).width() < 1440) {
        //dropdown
        /**
         * check if parent has child with classname
         * on click add or remove class
        */
        parent.forEach(function (ele) {
            ele.addEventListener('click', function(e) {
                e.preventDefault();
                if (ele.parentNode.classList.contains('show-ul')) {
                    window.location = e.target.href;
                }
                else {
                    ele.parentNode.classList.add("show-ul");
                    header.classList.add('submenu-active');
                }
            });
        });
        arrow.forEach(function(ele) {
            ele.addEventListener('click', function(e) {
                e.preventDefault();
                if (ele.parentNode.classList.contains('show-ul')) {
                    ele.parentNode.classList.remove("show-ul");
                    header.classList.remove('submenu-active');
                }
                else {
                    ele.parentNode.classList.add("show-ul");
                    header.classList.add('submenu-active');
                }
            });
        });

        backBtn.addEventListener('click', function(e) {
            e.preventDefault();
            header.querySelector('li.show-ul').classList.remove("show-ul");
            header.classList.remove('submenu-active');
        });
        }

    });
}