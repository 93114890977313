const newsBlock = document.querySelector('section.news-preview');

if(newsBlock) {
    let wrapper = newsBlock.querySelector('.wrapper');
    let articles = newsBlock.querySelectorAll('article.tease');
    articles.forEach(function(article, index) {
        article.addEventListener('mouseover', function() {
            wrapper.classList.add('hovered');
        });
        article.addEventListener('mouseleave', function() {
            wrapper.classList.remove('hovered');
        });
    });
}