// Smooth scroll to anchor links
$('a').click(function(e) {
    if(this.href.substring(this.href.indexOf('#') + 1) != 'content-section') {
        scrollToAnchor(this.href, e, 100);
    }
});

function scrollToAnchor(href, event, offset) {
    if (href.length <= 1) {
        return;
    }

    var hashIndex = href.indexOf('#');

    if (hashIndex === -1) {
        return;
    }

    var currentPath = window.location.origin + window.location.pathname;
  /*   if (hashIndex > 0 && href.substring(0, hashIndex) !== currentPath) {
        return;
    } */

    var id = href.substring(hashIndex + 1);

    if (!id) {
        return;
    }

    var $el = $('#' + id);

    if ($el.length === 0){
        return;
    }

    closeMenu();

    $('html, body').animate({
        scrollTop: $el.offset().top - (offset || 0)
    }, 300);

    if (event) {
        event.preventDefault();
        //window.location.hash = id;
    }
}

window.scrollToAnchor = scrollToAnchor;

if (window.location.hash) {
    var target = window.location.hash,
    target = target.replace('#', '');
    window.location.hash = "";

    closeMenu();

    setTimeout(function () {
        $('html,body').animate({
            scrollTop: $("#" + target).offset().top - 80
        }, 300);
    }, 900)
}

$('footer .to-top-btn button').click(function(e) {
    e.preventDefault();
    $('html, body').animate({
        scrollTop: 0
    }, 300);
});

function closeMenu() {
    $('header').removeClass('show-menu menu-bg');
    $('header').find('.wrapper').removeClass('anim');
    $('header').find('.nav-wrapper').removeClass('open');
    $('header').find('.menu-content').removeClass('show anim');
    $('header').find('.menu-btn-wrapper button').removeClass('menu-close');
    $('header').find('.menu-btn-wrapper p').html('Menu');
    $('body').removeClass('no-scroll');
    if($('header').hasClass('submenu-active')){
        $('header').find('li.show-ul').removeClass("show-ul");
        $('header').removeClass('submenu-active');
    }
}