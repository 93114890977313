import { intersectionObserve } from "./_intersection-observer";
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

const elements = document.querySelectorAll('section.key-figures.with-slideshow');
let gapCalc;
if(window.innerWidth < 1024) {
  gapCalc = (100% - (window.innerWidth * 19.275)) / 2;
}
else if(window.innerWidth < 1440) {
  gapCalc = (window.innerWidth - 840) / 2;
}
else if(window.innerWidth < 1728) {
  gapCalc = (1128 / 4) + (window.innerWidth - 1128) / 2;
}
else {
  gapCalc = (1260 / 4) + (window.innerWidth - 1260) / 2;
}

function initImageSlider(id) {
    let slide = new Splide('#' + id, {
      type: 'loop',
      perPage: 3.5,
      gap: '36px',
      perMove: 1,
      drag: 'free',
      autoScroll: {
        speed: 0.5,
        autoStart: true,
      },
      arrows: false,
      pagination: false,
      padding: {
        left: gapCalc
      },
      breakpoints: {
        2268: {
          perPage: 3
        },
        1440: {
          perPage: 2.5,
          gap: '24px',
        },
        1024: {
          perPage: 1.5,
          gap: '16px',
        },
      }
    });
    slide.mount({ AutoScroll });
};

function initSlider(id) {
  let slide = new Splide('#' + id, {
    type: 'slide',
    perPage: 6,
    perMove: 1,
    arrows: false,
    pagination: false,
    drag: 'free',
    slideFocus: 0,
    autoplay: false,
    autoScroll: {
      speed: 0.8,
      autoStart: false,
    },
    padding: {
      left: gapCalc
    },
    breakpoints: {
      3099: {
        perPage: 5,
      },
      2699: {
        perPage: 4,
      },
      2299: {
        perPage: 3,
      }
    }
  });
  slide.mount({ AutoScroll });

  intersectionObserve(elements, 0, 0.5, (element, intersectionRatio) => {
    (function(el) {
        if (intersectionRatio > 0 && !document.querySelector('#' + id).classList.contains('started')) {
          slide.destroy();
          slide.options = { autoScroll: {speed: 0.8, autoStart: true} };

          slide.on('mounted', function() {
            document.querySelectorAll('#' + id + ' .splide__slide.title').forEach(function(slide, index) {
              let height = slide.offsetHeight;
              slide.style.minWidth = height + 'px';
              if(document.querySelector('body').classList.contains('home')){
                slide.style.maxWidth = height + 8 + 'px';
              }
           /*    slide.style.maxWidth = height + 'px';
              slide.style.maxHeight = height + 'px'; */
            });
          });

          slide.mount({ AutoScroll });

          document.querySelector('#' + id).classList.add('started');


        } 
    })(element); 
  })
};

window.initImageSlider = initImageSlider;
window.initSlider = initSlider;