import { intersectionObserve } from "./_intersection-observer";

const heroBlock = document.querySelector('.hero-frontpage')

if(heroBlock) {
    let elements = heroBlock.querySelectorAll('.box');

    intersectionObserve(elements, 0, 1, (element, intersectionRatio) => {
        (function(el) {
            if (intersectionRatio == 1 && !el.classList.contains('show')) {
                el.classList.add('show');
            } 
        })(element);
    });
}