let rigsBlock = document.querySelector("section.rigs");

if (rigsBlock && window.innerWidth >= 1024) {
  let containerAddedHeight = 0;
  window.addEventListener("scroll", function () {
    let scroll = window.scrollY;
    let speedImage = 0.2;
    let speedBox = 0.1;

    rigsBlock.querySelectorAll(".rig-item").forEach(function (item, index) {
      let image = item.querySelector(".image");
      let box = item.querySelector(".box");
      let container = item.parentNode;
      let containerHeight = container.offsetHeight;
      let topOffset = item.offsetTop - 500;
      let height = item.offsetHeight;
      let parallaxSizeImage = (scroll - topOffset) * speedImage;
      let parallaxSizeBox = (scroll - topOffset) * speedBox;

      // Prevent parallax when scrolled past elememt
      if (scroll > topOffset + height) {
        return;
      }
      if (item.classList.contains("image-right")) {
        image.style.transform =
          scroll >= topOffset ? "translateY(-" + parallaxSizeImage + "px)" : "";
        box.style.transform =
          scroll >= topOffset ? "translateY(" + parallaxSizeBox + "px)" : "";
      } else {
        image.style.transform =
          scroll >= topOffset ? "translateY(" + parallaxSizeImage + "px)" : "";
        box.style.transform =
          scroll >= topOffset ? "translateY(-" + parallaxSizeBox + "px)" : "";
      }

      if (scroll >= topOffset && containerAddedHeight < height / 4) {
        container.style.height = containerHeight + 1 + "px";
        containerAddedHeight++;
      }
    });
  });
}